import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
// import { useState,useEffect } from 'react/cjs/react.development';
import { useDispatch, useSelector } from "react-redux";
// import { postLoginAsync } from "../../Redux/AuthSlice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// import messageSlice from "../../../Redux/MessageSlice";
import {
  useResendOtpMutation,
  useSignupMutation,
  useVerifyAccountMutation,
} from "../../store/slices/apis/authApi";
import { setAuthenticated, setUser } from "../../store/slices/authSlice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Input from "../../Components/Utils/Input";

export default function StudentRegister() {
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [isOtpSent, setOtpSent] = useState(false);
  const [token, setToken] = useState(null);
  // const { message } = useSelector((state) => state.message);
  const [signup] = useSignupMutation();
  const [resendOtp] = useResendOtpMutation();
  const [verifyAccount] = useVerifyAccountMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef();
  const formikRef1 = useRef();
  const [userData, setUserData] = useState();
  const [disabled, setDisabled] = useState(false);

  // const formik = useFormik({
  //   initialValues: {
  //     gender: "",
  //   },
  //   onSubmit: (values) => {
  //     // Handle form submission
  //   },
  // });
  // useEffect(() => {
  //   dispatch(messageSlice.actions.clearMessage());
  // }, [dispatch]);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    gender: "",
    password: "",
    rePassword: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    email: Yup.string()
      .required("This field is required!")
      .email("Please enter a valid email"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Please enter a 10 digit mobile number")
      .max(10, "Please enter a 10 digit mobile number"),
    gender: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
    rePassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    // mobileOtp: Yup.string().required("This field is required!"),
    // emailOtp: Yup.string().required("This field is required!"),
  });
  const otpValidationSchema = Yup.object().shape({
    phoneOtp: Yup.string().required("This field is required!"),
    emailOtp: Yup.string().required("This field is required!"),
  });
  // const handleChangeFormData = (event)=>{
  //   //  console.log(event.target.name);
  //   const values = {...formData};
  //   values[event.target.name] = event.target.value;
  //  //  console.log(values);
  //   setFormData( values);
  // }
  const handleRegister = async (formValue) => {
    // console.log("handleOtp", formValue);
    setLoading(true);
    try {
      const result = await signup(formValue).unwrap();
      if (result.status) {
        toast.success("OTP Has been sent to your email and phone number!");
        setOtpSent(true);
        setToken(result.token);
        setUserData(formValue);
        //  dispatch(setAuthenticated(true));
        //  dispatch(setUser(result.user || {}));
        // navigate("/dashboard");
        // window.location.reload();
      } else {
      }
    } catch (err) {
      console.log("error ", err);
      toast.error(err.data.message);
    } finally {
      // resetForm();
      setLoading(false);
    }
  };

  const resend_OTP = async () => {
    // console.log("handleOtp", formValue);

    if (disabled) {
      return;
    }

    // Disable the button
    setDisabled(true);

    // Set a timer to re-enable the button after 60 seconds
    setTimeout(() => {
      setDisabled(false);
    }, 60000);

    try {
      const result = await resendOtp(userData).unwrap();
      if (result.status) {
        toast.success("OTP Has been sent to your email and phone number!");
        setOtpSent(true);
        setToken(result.token);

        //  dispatch(setAuthenticated(true));
        //  dispatch(setUser(result.user || {}));
        // navigate("/dashboard");
        // window.location.reload();
      } else {
      }
    } catch (err) {
      console.log("error ", err);
      toast.error(err.data.message);
    } finally {
      // resetForm();
      setLoading(false);
    }
  };

  const handleVerifyAccount = async (formValue) => {
    setLoading(true);
    try {
      const result = await verifyAccount(formValue).unwrap();
      if (result.status) {
        toast.success("Registration Successful");
        dispatch(setAuthenticated(true));
        dispatch(setUser(result.user || {}));
        navigate("/dashboard");
        window.location.reload();
      } else {
      }
    } catch (err) {
      console.log("error ", err);
      toast.error(err.data.message);
    } finally {
      // resetForm();
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    navigate("/dashboard");
    window.location.reload();
    return <Navigate to='/dashboard' />;
  }

  return (
    <>
      <div className='min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8  mt-10'>
        {!isOtpSent && (
          <div className='max-w-xl w-full space-y-8 shadow-2xl p-10 rounded-lg'>
            <div>
              <img
                className='mx-auto h-12 w-auto rounded-full'
                src='https://erp.dibru.work/images/dibru.png'
                alt='Logo'
              />
              <h2 className='mt-6 text-center text-xl font-extrabold text-gray-600'>
                Dibrugarh University Convocation Portal
              </h2>
              <h2 className='mt-1 text-center text-md font-medium text-gray-600'>
                Please enter the following details to register
              </h2>
            </div>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRegister}
            >
              <Form className='space-y-2'>
                <input type='hidden' name='remember' defaultValue='true' />
                <Input
                  label='Enter your Name'
                  name='name'
                  type='text'
                  // label="Enter Your Name"
                  placeholder='Enter Your Name'
                />

                <Input
                  name='email'
                  type='email'
                  label='Enter Your Email'
                  placeholder='Enter Your Email'
                />

                <Input
                  type='phone'
                  name='phone'
                  label='Enter your mobile number'
                  placeholder='Enter your mobile number'
                />

                <CustomSelect
                  options={[
                    { id: 0, name: "Male" },
                    { id: 1, name: "Female" },
                    { id: 2, name: "Other" },
                  ]}
                  name='gender'
                  label='Select your gender'
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option.name} `}
                  onChange={(selectedOption) => {
                    formikRef.current?.setFieldValue(
                      "gender",
                      selectedOption.id
                    );
                  }}
                />

                <Input
                  type='password'
                  name='password'
                  placeholder='Enter your mobile password'
                  label='Enter your Password'
                />

                <Input
                  type='password'
                  name='rePassword'
                  label='Enter your Password'
                  placeholder='Enter your password'
                />

                <div className='mt-5'>
                  <button
                    type='submit'
                    className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    disabled={loading}
                  >
                    {loading && (
                      <span className='spinner-border spinner-border-sm'></span>
                    )}
                    <span>Register</span>
                  </button>
                </div>

                <div className='mt-5'>
                  <span>Already have an account? </span>
                  <Link to='/student-login' className='text-indigo-600'>
                    Sign In{" "}
                  </Link>
                </div>
              </Form>
            </Formik>
            {/* {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )} */}
          </div>
        )}

        {isOtpSent && (
          <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-10'>
            <div className='max-w-lg w-full space-y-8 shadow-2xl p-10 rounded-lg'>
              <div>
                <img
                  className='mx-auto h-12 w-auto rounded-full'
                  src='https://erp.dibru.work/images/dibru.png'
                  alt='Logo'
                />
                <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-600'>
                  Registration
                </h2>
                <h2 className='mt-1 text-center text-xl font-medium text-gray-600'>
                  Please enter the following details
                </h2>
              </div>
              <Formik
                innerRef={formikRef1}
                initialValues={{
                  phoneOtp: "",
                  emailOtp: "",
                  token: token,
                }}
                validationSchema={otpValidationSchema}
                onSubmit={handleVerifyAccount}
                enableReinitialize={true}
              >
                <Form>
                  <input type='hidden' name='token' />
                  <div className='mt-5'>
                    <Input
                      type='number'
                      name='phoneOtp'
                      label='Enter Your Mobile OTP'
                      placeholder='Enter your Mobile OTP'
                    />
                  </div>

                  <div className='mt-5'>
                    <Input
                      type='number'
                      name='emailOtp'
                      label='Enter Your Email OTP'
                      placeholder='Enter your Email OTP'
                    />
                  </div>

                  <div className='mt-5 flex flex-row justify-between'>
                    <button
                      type='submit'
                      className='group relative w-16 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      {loading && (
                        <span className='spinner-border spinner-border-sm'></span>
                      )}
                      <span>Verify</span>
                    </button>
                    <button
                      type='button'
                      className={`group relative w-32 center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                        disabled
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      }`}
                      disabled={disabled || loading}
                      onClick={resend_OTP}
                    >
                      {loading && (
                        <span className='spinner-border spinner-border-sm'></span>
                      )}
                      <span>Resend OTP</span>
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
