import React, { useState } from "react";
import styles from "./../../styles/Stepper.module.css";
import { HiCheckCircle } from "react-icons/hi";
import { FiCircle } from "react-icons/fi";
import { ApplicationStatus } from "../../Utils/Status";
const Stepper = ({ data, status }) => {
  // console.log("Stepper status", data.status);
  const activeStyle = { width: 40, height: 40, color: "#6366F1" };
  const inActiveStyle = { width: 40, height: 40, color: "#cccccc" };
  return (
    <div>
      <h3 className='m-3 text-lg font-bold text-left'>
        {data?.certificate_name || ""}
        <p>
          {" "}
          Status:
          {data?.admin_status == 0 && "Pending"}
          {data?.admin_status == -1 && "Rejected"}
          {data?.admin_status == 4 && "Issued"}{" "}
        </p>
      </h3>
    </div>
  );
};

export default Stepper;
